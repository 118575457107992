* {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}
html, body {
  margin: 0;
  height: 100%;
  background-color: #8b5a2b;
}
/* Light Theme */
.App.light {
  background-color: #8b5a2b;
  width: auto;
}

.light > * {
  color: #f2dfbb !important;
}

.bgimage.light {
  height:100vh;
  background-color: #ffa54f;
  background-size:cover;
  position:relative;
  transition: all 0.5s ease;
}

.hero_title.light {
  font-size: 4.5rem;
}

.hero_desc.light {
  font-size: 2rem;
}

.hero-text.light {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar.light.top {
  background-color: #9d6c11;
  opacity: 90%;
  position: fixed;
  z-index: 100;
  width: 100%;
}
.navbar.light.bottom {
  background-color: #8b5a2b;
  opacity: 90%;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.dropdown-menu-light {
  background-color: #8b5a2b !important;
}

.dropdown-item.light {
  color: #f2dfbb !important;
}

.dropdown-item.light:hover {
  background-color: #8b4513 !important;
}

.nav-dropdown.light > a {
  color: #f2dfbb !important;
}

.imageWrapper {
  max-width: 400px;
  min-width: 200px
}

.imageAboutPage {
  width: 100%;
  height: auto;
}

.qualifications-wrapper {
  min-height: 250px;
  margin-bottom: 50px;
}

.servicesText {
  min-height: 250px;
}

.card-body.light {
  min-height: 250px;
  background-color: #a0522d;
}
.card.light {
  background-color: #a0522d !important;
}

.footer-bar.light {
  background-color: #ffa54f !important;
  text-align: center;
  opacity: 60%;
}


/* Dark Theme */
.App.dark {
  background-color: #62764f;
  width: auto;
}
.dark > * {
  color: #b8f0b2 !important;
}
.bgimage.dark {
  height:100vh;
  background-color: #3e543c;
  background-size:cover;
  position:relative;
  transition: all 0.5s ease;
}

.hero_title.dark {
  font-size: 4.5rem;
}

.hero_desc.dark {
  font-size: 2rem;
}

.hero-text.dark {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar.dark.bottom {
  background-color: #2d4228;
  opacity: 90%;
  position: fixed;
  z-index: 100;
  width: 100%;
}
.navbar.dark.top {
  opacity: 90%;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.dropdown-menu-dark {
  background-color: #265021 !important;
}
.dropdown-item.dark {
  color: #b8f0b2 !important;
}
.dropdown-item.dark:hover {
  background-color: #333d2a!important;
}

.nav-dropdown.dark > a {
  color: #b8f0b2 !important;
}

.imageWrapper {
  max-width: 400px;
  min-width: 200px
}

.imageAboutPage {
  width: 100%;
  height: auto;
}

.qualifications-wrapper {
  min-height: 250px;
  margin-bottom: 50px;
}


.servicesText {
  min-height: 250px;
}

.card-body.dark {
  min-height: 250px;
  background-color: #3e543c
}
.card.dark {
  background-color: #3e543c !important;
}

.footer-bar.dark {
  background-color: #3e543c !important;
  text-align: center;
  opacity: 60%;
}